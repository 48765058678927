
import { catchError, throwError } from 'rxjs';
import { inject } from "@angular/core";
import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { ContextService } from '@apply4u/services/context-service';
import { AuthService } from '@apply4u/services/auth-services/auth.service';

export const AuthInterceptor: HttpInterceptorFn  = (req, next) => {

    const authService = inject(AuthService);
    const contextService = inject(ContextService);

    if (contextService.IsBrowser && contextService.IsUserLoggedIn) {
        req = req.clone({ setHeaders: { Authorization: authService.GetAuthorizationHeaderValue() } });
    }

    return next(req).pipe(catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.statusText == "Unauthorized") {
            if (contextService.IsBrowser) {
                authService.PostSignOutSettings(false);
                contextService.SignInRedirect(true);
            }
        }

        return throwError("Something went wrong while processing http request.");
    }));

}