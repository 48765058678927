import { Component, HostListener, OnInit } from '@angular/core';

import { ContextService } from './services/context-service';
import { ScrollToTop } from './shared/helpers/common/common-helpers';
import { IsNotNull, IsNotNullOrEmpty } from './shared/helpers/common/type-safe-helpers';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone:true,
    imports:[RouterModule]
})
export class AppComponent implements OnInit {
    constructor(public ContextService: ContextService,
        private router: Router) {            
    }

    ngOnInit() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };

        ScrollToTop(this.ContextService.IsBrowser);
        this.MediaTypeDetector();
    }

    OnDeactivate() {
      if(this.ContextService.IsBrowser){
        document.body.scrollTop = 0;
      }        
    }

    @HostListener('window:resize', ['$event'])
    OnResize(event?) {
        this.MediaTypeDetector();
    }

    MediaTypeDetector() {
        if (this.ContextService.IsBrowser) {
            let mediaTypeDetectorInterval = setInterval(() => {
                let element = document.getElementById('MediaTypeDetector');

                if (IsNotNull(element)) {
                    clearInterval(mediaTypeDetectorInterval);
                    let type = getComputedStyle(element).content;

                    if (IsNotNullOrEmpty(type)) {
                        this.ContextService.IsMobileView = this.ContextService.IsDesktopView = false;

                        let typeNumber = parseInt(type.replace("\"", ""));

                        if (typeNumber == 1 || typeNumber == 2) {
                            this.ContextService.IsMobileView = true;
                        } else {
                            this.ContextService.IsDesktopView = true;
                        }
                    } else {
                        this.ContextService.IsDesktopView = true;
                    }
                }
            }, 100);
        }
    }
}
